.card-container {
    @apply flex w-fit h-screen items-center justify-center gap-x-7 ease-in-out;

    user-select: none;
}

.showcase-card {
    @apply rounded-t-full w-full h-full relative grid grid-cols-1 grid-rows-1;
}

.project-card-img {
    @apply h-full object-cover z-0 ;
}

.c-overlay {
    @apply col-start-1 col-span-1 row-start-1 row-span-1;
}

.overlay-gradient {
    @apply bg-gradient-to-t from-black to-transparent opacity-0 z-20;

    @apply group-hover:opacity-25 duration-700 delay-100;
}

.window-shape {
    @apply rounded-t-full
}

.project-card-container {
    @apply grid grid-cols-1 grid-rows-1;

    transform-style: preserve-3d;
    width: 200px;
    height: 380px;
}

@media (min-width: 1024px) {
    .project-card-container {
        width: 300px;
        height: 570px;
    }

    .card-container {
        @apply gap-x-16
    }
}

.project-card-title {
    @apply justify-self-center self-end font-space pointer-events-none h-1/3 w-2/3;

    @apply duration-700 ease-in-out scale-150;

    transform: translate3d(0, 0, 40px);
}

.project-card-title > h2 {
    @apply opacity-0 text-2xl text-center;

    @apply peer-hover:opacity-100 duration-700;
}