.nav-container-mobile {
    @apply h-fit w-full bg-transparent text-black;

    @apply grid grid-cols-3;
}

.nav-container-mobile > .nav-icon-container {
    @apply h-full
}

.nav-icon-container > a > svg {
    @apply h-10
}

.nav-container-mobile > .nav-check-container {
    @apply grid col-start-3
}

.mobile-menu-container > .nav-btn {
    @apply font-recoleta text-4xl tracking-wide;
}

.mobile-menu-container > .nav-btn-mono {
    @apply font-recoleta text-base tracking-wide;
}

.nav-btn-mono {
    @apply font-ibmplex-mono text-sm;

    text-transform: uppercase;
}

.rounded-btn {
    @apply rounded-full w-full h-full bg-soik-red z-0;
}

.mobile-btn-container {
    @apply fixed top-0 flex flex-col w-full h-full bg-[#020205] text-white;

    transform: translateX(100%)
}

.mobile-menu-container {
    @apply flex flex-col gap-y-3
}

.nav-check-container {
    @apply w-full h-full justify-end items-center;
}

.nav-check {
    @apply grid grid-cols-1 grid-rows-1 w-8 h-8 z-10;
}

.overlay {
    @apply col-start-1 col-span-1 row-start-1 row-span-1;
}

.hidden-check {
    @apply w-full h-full z-20 opacity-0;

    @apply hover:cursor-pointer;
}

.nav-main {
    @apply fixed top-0 flex-col w-full p-2 h-fit duration-700 ease-in-out;
}

.nav-toggle-btns {
    @apply grid grid-cols-1 grid-rows-1 h-full w-full
}

.hamburger-icon {
    @apply flex flex-col h-full justify-evenly items-end
}

.hamburger-line-1 {
    @apply h-[1px] w-full bg-white
}

.hamburger-line-2 {
    @apply h-[1px] w-2/5 bg-white
}

.exit-icon {
    @apply flex flex-col h-full rotate-45 translate-x-1 -translate-y-1
}

.exit-line-1 {
    @apply h-[1px] w-full bg-black rotate-90 translate-y-[20px]
}

.exit-line-2 {
    @apply h-[1px] w-full bg-black translate-y-[19px]
}

.btn-animate {
    transform: translateY(100%);
    opacity: 0;
}