.about-main-container {
    @apply flex flex-col mt-10
}

.about-list-header {
    @apply h-fit w-full flex justify-center
}

.about-list-main {
    @apply bg-white h-fit flex flex-col
}

.page-header-wrapper {
    @apply h-full w-full p-2 flex flex-col my-16 max-w-md md:max-w-2xl
}

.about-list-container {
    @apply p-2 py-16 h-full grid grid-cols-2 gap-y-16 md:gap-y-32 md:gap-x-24 max-w-5xl self-center;
}

.about-listing-card {
    @apply col-span-2 h-fit w-full md:col-span-1
}

.about-listing-card-container {
    @apply text-black grid gap-y-2;

    grid-template-rows: 3 auto;
}