@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'IBM Plex Mono';
    src: url(./Fonts/IBMPlexMono-Regular.ttf);
}

@font-face {
    font-family: 'Recoleta';
    src: url(./Fonts/recoleta-regulardemo.otf);
}

@font-face {
    font-family: 'Space Grotesk';
    src: url(./Fonts/SpaceGrotesk-VariableFont_wght.ttf);
}

h1 {
    @apply text-6xl
}

h2 {
    @apply text-5xl
}

h3 {
    @apply text-4xl font-semibold
}

h4 {
    @apply text-3xl font-bold
}

p, a { 
    @apply text-2xl
}

.page-head-container {
    @apply h-40 flex mx-4 items-center
}

.page-header {
    @apply font-recoleta text-6xl md:text-8xl tracking-wide
}

#root {
    @apply bg-gradient-to-t bg-[#020205] text-white;
}