.pp-main-container {
    @apply flex flex-col
}

.default-main {
    @apply bg-white text-black h-fit flex flex-col
}

.pp-header-wrapper {
    @apply h-full w-full p-2 flex flex-col
}