.project-main-container {
    @apply flex flex-col mt-10
}

.project-list-header {
    @apply h-fit w-full flex justify-center
}

.project-list-main {
    @apply bg-white h-fit flex flex-col pb-12
}

.page-header-wrapper {
    @apply h-full w-full p-2 flex flex-col my-16 max-w-md md:max-w-2xl
}

.project-list-container {
    @apply p-2 py-36 h-full grid grid-cols-2 gap-y-16 md:gap-y-32 md:gap-x-24 max-w-5xl self-center;
}

.project-listing-card {
    @apply col-span-2 h-fit w-full md:col-span-1
}

.project-listing-card-container {
    @apply text-black grid gap-y-2;

    grid-template-rows: 3 auto;
}

.lc-description {
    @apply font-ibmplex-mono text-xs h-8 w-full leading-none grid grid-cols-6 gap-x-1 items-end;

    text-transform: uppercase;
}

.lc-thumbnail-container {
    @apply w-full;
}

.card-thumbnail {
    @apply w-full object-cover aspect-[16/9]
}

.lc-title {
    @apply font-space text-3xl
}

.filter-section {
    @apply font-mono text-xs h-full flex items-center justify-center px-4 overflow-x-visible;

    text-transform: uppercase;
}